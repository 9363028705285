/* Home page CSS Only */
.campfire-page .board-body {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.campfire-page.container-fluid {
    max-width: 1300px;
    /* padding-top: 97px; */
    padding-bottom: 60px;
    padding-left: 0px;
    margin-left: 15px;
}

/* .campfire-page h2 {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 800;
    line-height: 37.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
} */

.campfire-page p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.campfire-page .campfire-body {
    margin-top: 50px;
}

.campfire-page .panel-default {
    margin-bottom: 15px;
}

.campfire-page .panel-default>.panel-heading {
    background-color: unset;
    border: none;
    font-family: Roboto;
    font-size: 18px !important;
    font-weight: 800 !important;
    line-height: 21.09px !important;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.campfire-page .panel-default>.panel-heading a:hover,
.campfire-page .panel-default>.panel-heading a:focus {
    text-decoration: none;
}

.campfire-page .panel-default>.panel-heading small,
.section-heading small {
    display: inline-block;
    padding: 0 5px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.campfire-page .chevron-icon {
    margin-right: 10px;
    color: #878684;
    font-size: 12px;
}

.campfire-page .panel-body {
    background-color: unset;
    border-top: unset !important;
    padding: 5px 45px;
}

.campfire-page .action-buttons {
    display: flex;
    /* justify-content: space-between; */
    gap: 15px;
    margin-top: 15px;
    padding: 0 15px;
}

.campfire-page .action-buttons button {
    border: unset;
    border-radius: 50px;
    padding: 5px 15px;

    font-family: Roboto;
    font-weight: 800;
    font-size: 16px;
    line-height: 18.75px;
    letter-spacing: -0.39px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    white-space: nowrap;
    gap: 5px;
}

.campfire-page .action-buttons button img {
    width: 14px;
}

.campfire-page .action-buttons button.primary.yellow {
    background-color: #F3D168;
}

.campfire-page .action-buttons button.primary.blue {
    background-color: #81B9EF;
}

.campfire-page .action-buttons button.primary.green {
    background-color: #82C2C0;
}

.campfire-page .panel-body table {
    border-bottom: 1px solid #d1d1d1;
}

.campfire-page .panel-body table thead tr th:nth-child(3) {
    text-align: unset;
}

.campfire-page .panel-body table thead tr th {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #878684;
    white-space: nowrap;
}

.campfire-page .panel-body table thead tr th.name {
    max-width: 20px;
}

.campfire-page .panel-body table tbody tr td:nth-child(3) {
    text-align: unset;
}

.campfire-page .panel-body table tbody tr td:not(.actions) img {
    width: 12px;
    /* opacity: 0.5; */
    cursor: pointer;
}

.campfire-page .panel-body table tbody tr td:nth-child(4) a,
.campfire-page .panel-body table tbody tr td:nth-child(4) a:hover {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: auto;
    color: #000;
    /* margin-right: 15px; */
}

.campfire-page .panel-body table tbody tr td {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.campfire-page .panel-body table tbody tr td:nth-child(1) {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.campfire-page .campfire-footer {
    margin-bottom: 50px;
}

/* .campfire-page .campfire-header h2, */
.campfire-page .campfire-footer h2 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    line-height: 21.09px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.campfire-page .campfire-footer h2 {
    margin: 15px 0 !important;
    padding-left: 15px;
}

.campfire-page .campfire-footer p {
    padding-left: 15px;
}

.campfire-page .panel-default>.panel-heading .panel-title h4 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    line-height: 21.09px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.table {
    width: 100%;
}

.table-responsive::-webkit-scrollbar,
.table-responsive::-moz-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    scrollbar-width: thin;
}

.campfire-page .custom-badge {
    margin-bottom: 0;
    display: inline-flex;
    gap: 3px;
    margin-right: 5px;
    padding: 5px 10px;
    color: #000;
}

.campfire-page .section-heading {
    display: flex;
    gap: 5px;
    align-items: anchor-center;
    font-family: Roboto;
    font-weight: 800;
    font-size: 18px;
    line-height: 21.09px;
    letter-spacing: 0px;
    padding: 0 15px;
    margin: 20px 0;
}

.campfire-page .section-heading .icon {
    width: 18px;
    opacity: 0.5;
    cursor: pointer;
}

.campfire-page .td-fte .custom-badge {
    font-family: Roboto;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.31px;
}

.campfire-page td.td-date,
.campfire-page td.td-name,
.campfire-page td.td-list,
.campfire-page td.td-member,
.campfire-page td.td-pills,
.campfire-page td.td-feedback,
.campfire-page td.td-notes,
.campfire-page .td-fte {
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow: hidden;
}

.campfire-page #feedback td.td-date {
    white-space: nowrap;
}

.campfire-page #feedback td.td-date,
.campfire-page #feedback td.td-name {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.campfire-page td.td-name {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
}

.campfire-page td.td-list {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    white-space: nowrap;
}

.campfire-page td.td-notes {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
}

.campfire-page td.td-feedback {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
}

.campfire-page .td-fte {
    width: 200px;
    min-width: 300px;
    max-width: 200px;
}

.campfire-page td.td-member {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
}

.campfire-page td.td-pills {
    
}

.campfire-page td.actions {
    width: 80px;
}

.campfire-page td.actions div {
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
}

/* .campfire-page .table>tbody>tr>td, 
.campfire-page .table>tbody>tr>th, 
.campfire-page .table>tfoot>tr>td, 
.campfire-page .table>tfoot>tr>th, 
.campfire-page .table>thead>tr>td, 
.campfire-page .table>thead>tr>th {
    border-top: unset !important;
} */

.campfire-page .badges-container {
    display: inline-flex;
}

.campfire-page .list-green {
    background-color: #7fc2c0;
}

.campfire-page .collapsed .icon-wrapper {
    background-color: #eeeeee;
}

.campfire-page .expanded .icon-wrapper {
    /* background-color: #dddddd; */
    border: 1px solid #dddddd;
}

.campfire-page .tracking-part h2 {
    display: flex;
    justify-content: start;
    align-items: center;
}

.campfire-page .icon-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    /* padding: 5px; */
    margin-left: 30px;
    border-radius: 5px;
}

.campfire-page .icon-wrapper img {
    width: 28px;
    height: 28px;
}