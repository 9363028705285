/* Home page CSS Only */
.home-page .board-body {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.home-page.container-fluid {
    max-width: 1300px;
    /* padding-top: 97px; */
    padding-bottom: 60px;
    padding-left: 0px;
    margin-left: 15px;
}

/* .home-page h2 {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 800;
    line-height: 37.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
} */

.home-page p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.home-page .home-body {
    margin-top: 50px;
}

.home-page .panel-default {
    margin-bottom: 15px;
}

.home-page .panel-default>.panel-heading {
    background-color: unset;
    border: none;
    font-family: Roboto;
    font-size: 18px !important;
    font-weight: 800 !important;
    line-height: 21.09px !important;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.home-page .panel-default>.panel-heading a:hover,
.home-page .panel-default>.panel-heading a:focus {
    text-decoration: none;
}

.home-page .panel-default>.panel-heading small {
    display: block;
    padding: 5px 29px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.home-page .chevron-icon {
    margin-right: 10px;
    color: #878684;
    font-size: 12px;
}

.home-page .panel-body {
    background-color: unset;
    border-top: unset !important;
    padding: 5px 45px;
}

.home-page .panel-body .action-buttons {
    display: flex;
}

.home-page .panel-body button {
    width: 187px;
    height: 44px;
    top: 725px;
    left: 170px;
    grid-gap: 0px;
    gap: 0px;
    border-radius: 50px;
    /* opacity: 0px; */
    background-color: #F2D172;
    border: unset;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: center;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    color: #000;

    margin-right: 45px;
}

.home-page .panel-body button.secondary {
    background-color: #E7E7E7;
}

.home-page .panel-body table {
    border-bottom: 1px solid #d1d1d1;
    width: 100%;
}

.home-page .panel-body table thead tr th:nth-child(3) {
    text-align: center;
}

.home-page .panel-body table thead tr th {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    color: #878684;
}

.home-page .panel-body table tbody tr td:nth-child(3) {
    text-align: center;
}

.home-page .panel-body table tbody tr td:nth-child(3) img {
    width: 20px;
    opacity: 0.5;
    cursor: pointer;
}

.home-page .panel-body table tbody tr td:nth-child(4) a,
.home-page .panel-body table tbody tr td:nth-child(4) a:hover {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-style: solid;
            text-decoration-style: solid;
    text-underline-position: from-font;
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
    color: #000;
    margin-right: 15px;
}

.home-page .panel-body table tbody tr td {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.home-page .panel-body table tbody tr td:nth-child(1) {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.home-page .panel-body table tbody tr td.td-feature {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
}

.home-page .panel-body table tbody tr td.td-description {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
}

.home-page .panel-body table tbody tr td.td-info {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}

/* .home-page .home-header h2, */
.home-page .home-footer h2 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 800;
    line-height: 21.09px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.home-page .home-footer h2 {
    margin-bottom: 0;
}

.home-page .home-footer p {
    padding: 5px 44px;
}

.home-page .panel-default>.panel-heading .panel-title h4 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    line-height: 21.09px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.table-responsive::-webkit-scrollbar,
.table-responsive::-moz-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    scrollbar-width: thin;
}

/* .home-page .tracking-part h2 {
    margin-top: 10px;
} */

.home-page .collapsed .icon-wrapper {
    background-color: #eeeeee;
}

.home-page .expanded .icon-wrapper {
    /* background-color: #dddddd; */
    border: 1px solid #dddddd;
}

.home-page .tracking-part h2 {
    display: flex;
    justify-content: start;
    align-items: center;
}

.home-page .icon-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    /* padding: 5px; */
    margin-left: 30px;
    border-radius: 5px;
}

.home-page .icon-wrapper img {
    width: 28px;
    height: 28px;
}

/* @media (max-width: 991px) {
    .home-page.container-fluid {
        padding-top: 67px;
    }

    .app-header {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }

    .left {
        grid-row: 1;
    }

    .center {
        grid-row: 2;
        width: 50%;
    }

    .right {
        grid-row: 2;
        width: 50%;
        grid-gap: 0 !important;
    }
}

@media (max-width: 768px) {
    .home-page.container-fluid {
        padding-top: 47px;
    }

    .home-page .panel-body button.secondary {
        margin-right: 0;
    }
}

@media (max-width: 580px) {
    .home-page .panel-body button.primary {
        margin-right: 10px;
    }
}

@media (max-width: 480px) {
    .home-page.container-fluid {
        padding-top: 27px;
    }

    .home-page .panel-body button {
        font-size: 14px;
    }
} */
/* Override fixes */
.app-board {
    padding-left: 70px;
}

.html2-canvas .app-board {
    padding-left: 0px;
}

.dropdown.total-fte .btn-mytpt {
    min-width: 105px;
}

/* .card-link, .card-unlink, .card-link-dl-focus {
    right: 0px !important;
    top: -10px !important;
} */
/* End */


.sidebar {
    position: absolute;
    top: 50px;
    left: 8px;
    width: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    /* border-right: 1px solid #eee; */
}

.html2-canvas .sidebar {
    display: none;
}

.sidebar .icon {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sidebar .icon:hover,
.sidebar .icon.active {
    background-color: #e0e0e0;
}

.sidebar .icon img {
    width: 24px;
    height: 24px;
}

.sidebar .separate-after::after {
    position: absolute;
    top: 50px;
    content: " ";
    width: 70%;
    height: 1px;
    background-color: #e0e0e0;
}

.sidebar .padlock {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 10px !important;
    height: auto !important;
}

.tippy-box[data-theme~="custom"] {
    background-color: black;
    /* Black background */
    color: white;
    /* White text */
    border-radius: 4px;
    /* Optional: Rounded corners */
    padding: 5px 10px;
    /* Optional: Add some padding */
    font-size: 14px;
    /* Optional: Font size */
}

.tippy-box[data-theme~="custom"] .tippy-arrow {
    color: black !important;
    /* Set the arrow color to black */
    fill: black !important;
    /* Ensure the arrow's SVG fill is black */
}

.tippy-box[data-theme~="custom"] .tippy-content {
    background-color: black;
    color: #fff;
}
/* Workflow page CSS Only */
.workflow-page .board-body {
    overflow-y: auto !important;
    /* overflow-x: auto !important; */
}

.workflow-page.container-fluid {
    /* max-width: 1300px; */
    /* padding-top: 97px; */
    padding-bottom: 60px;
    padding-left: 0px;
    margin-left: 15px;
}

.workflow-page p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.workflow-page .workflow-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px 0;
    margin-top: 50px;
    flex-wrap: wrap;
    /* Allows wrapping on smaller screens */
    margin-bottom: 30px;
}

.workflow-page .workflow-body .left-section {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.workflow-page .workflow-body .right-section {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.workflow-page .workflow-body .add-workflow {
    display: flex;
    justify-content: first baseline;
    align-items: center;
    cursor: pointer;
    color: #808080;
    font-weight: 500;
}

.workflow-page .workflow-body .clearable3 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.33px solid #808080;
    border-radius: 5px;
    width: 286px;
    padding-left: 10px;
}

.workflow-page .workflow-body .clearable3 .clearable__clear {
    display: block;
    color: #808080;
    top: 3px;
}

.workflow-page .workflow-body .clearable3 img {
    width: 18px;
    height: 18px;
    filter: opacity(0.5);
}

.workflow-page .workflow-body .clearable3 input {
    border-bottom: unset !important;
    padding-left: 5px !important;
}

.workflow-page .workflow-body .clearable3 input::placeholder {
    font-size: 16px;
}


/* Workflow rows */
.workflows {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
}

/* .html2-canvas .workflows {
    width: max-content;
} */

.workflows .actions {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.workflows .actions a {
    color: #808080;
    padding: 5px;
    display: flex;
}

.workflows .actions a:hover {
    text-decoration: none;
}

.workflows .actions a img {
    opacity: 0.8;
}

.workflows .workflow {
    display: flex;
    /* Aligns items in a row */
    grid-gap: 10px;
    gap: 10px;
    /* Adds space between items */
    /* padding: 10px; */
    min-width: -webkit-max-content;
    min-width: max-content;
    /* Ensures it doesn't shrink */
    border: 2px solid #B5B4B3;
    border-radius: 10px;
    padding-bottom: 40px;
    margin-bottom: 30px;
    position: relative;
}

.workflows .workflow .item {
    flex: 0 0 auto;
    /* Prevents items from shrinking */
    width: 300px;
    /* Set a fixed width or adjust as needed */
    /* border: 1px solid #ddd; */
    padding: 15px;
    /* background: white; */
    /* border-radius: 8px; */
    position: relative;
}

.workflow-page .workflows .workflow .new-step {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
    font-weight: 500;
    width: 150px;
}

.html2-canvas .new-step {
    display: none !important;
}

.workflow-page .workflows .workflow .item:not(.new-step)::after {
    content: "";
    position: absolute;
    right: -7px;
    /* Aligns to the right */
    top: 10%;
    /* Adjust to shorten from the top */
    height: 80%;
    /* Shorter height than the item */
    width: 1px;
    background-color: #B5B4B3;
    /* Same as border color */
}

.workflow-page .workflows .workflow .item.wf::after {
    width: 3px;
}

.workflow-page .workflows .item .title {
    font-size: 16px;
    font-weight: 500;
    color: #808080;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.workflow-page .workflows .item.wf .name {
    border-bottom: 5px solid #48DFB6;
    margin-bottom: 10px;
}

.workflow-page .workflows .item .name input,
.workflow-page .workflows .item .name input:focus,
.workflow-page .workflows .item .name input:active {
    outline: unset !important;
    border: unset !important;
    border-color: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    transition: unset !important;
    background-color: unset !important;
}

.workflow-page .workflows .item .name input,
.workflow-page .workflows .item .name input::placeholder {
    font-size: 22px;
    font-weight: 600;
    padding-left: 0;
}

.workflow-page .workflows .item .name input {
    color: #000000 !important;
}

.workflow-page .workflows .item .name input::placeholder {
    color: #555555 !important;
    opacity: 0.3;
}

.workflow-page .workflows .item.st .name {
    border-bottom: 5px solid #96C2EE;
    margin-bottom: 10px;
}

.workflow-page .workflows .item .owner-type {
    margin-top: 10px;
}

.workflow-page .workflows .item .owner {
    margin-bottom: 10px;
}

.workflow-page .workflows .item .notes textarea {
    color: #555555;
    border: none;
    border-radius: unset;
    height: 60px;
    width: 100%;
    resize: none;
    padding: 5px 5px 5px 0px;
    overflow: hidden;
    /* border-bottom: 1px solid #808080; */
    box-shadow: unset !important;
    font-size: 16px;
}

/* .workflow-page .tracking-part h2 {
    margin-top: 10px;
} */

.workflow-page .tracking-part p img {
    width: 16px;
}

.workflow-page .custom-badge.red-o {
    padding: 3px 7px;
}



.wfselect__value-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    align-items: baseline !important;
}

.wfselect__indicator-separator,
.multi-select-container .wfselect__clear-indicator {
    display: none !important;
}

.multi-select-container .wfselect__multi-value__remove svg {
    width: 20px;
    height: 20px;
    color: #CCCCCC !important;
}

.multi-select-container .wfselect__multi-value__remove svg:hover {
    color: #999999 !important;
}

.wfselect__indicators {
    align-items: baseline !important;
}

.wfselect__indicator {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.wfselect__control {
    border-color: unset !important;
    border-style: unset !important;
    border-width: unset !important;
    box-shadow: unset !important;
}

.wfselect__input-container {
    width: 100% !important;
    display: block !important;
}

.multi-select-container .wfselect__value-container--has-value .wfselect__input-container[data-value=""]::before {
    content: "Owner";
    position: absolute;
    bottom: 8px;
    left: 3px;
    font-size: 16px;
    color: #999999;
    pointer-events: none;
}

.wfselect__multi-value {
    width: 100% !important;
    justify-content: space-between !important;
    background-color: unset !important;
}

.wfselect__multi-value__label {
    padding-left: 0 !important;
}

.wfselect__multi-value__label div {
    font-size: 16px !important;
}

.wfselect__multi-value__label div .custom-badge.blue {
    margin-bottom: auto !important;
}

.wfselect__menu-list {
    padding: 0 !important;
}

.wfselect__option {
    padding: 5px !important;
    font-size: 16px !important;
    background-color: #091e420a !important;
}

.wfselect__multi-value__remove {
    background-color: unset !important;
    color: unset !important;
}

.wfselect--is-disabled .wfselect__multi-value__remove,
.wfselect--is-disabled .wfselect__input-container {
    display: none !important;
}


/* Disabled styles */
.wfselect__option--is-focused,
.wfselect__option--is-selected {
    background-color: #d9dbe4 !important;
    color: #000 !important;
}

.wfselect--is-disabled .wfselect__control{
    background-color: unset !important;
}

.wfselect--is-disabled .wfselect__dropdown-indicator {
    display: none !important;
}

.workflow-page .workflows .notes textarea:disabled {
    background-color: unset !important;
}
/* Home page CSS Only */
.campfire-page .board-body {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.campfire-page.container-fluid {
    max-width: 1300px;
    /* padding-top: 97px; */
    padding-bottom: 60px;
    padding-left: 0px;
    margin-left: 15px;
}

/* .campfire-page h2 {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 800;
    line-height: 37.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
} */

.campfire-page p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.campfire-page .campfire-body {
    margin-top: 50px;
}

.campfire-page .panel-default {
    margin-bottom: 15px;
}

.campfire-page .panel-default>.panel-heading {
    background-color: unset;
    border: none;
    font-family: Roboto;
    font-size: 18px !important;
    font-weight: 800 !important;
    line-height: 21.09px !important;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.campfire-page .panel-default>.panel-heading a:hover,
.campfire-page .panel-default>.panel-heading a:focus {
    text-decoration: none;
}

.campfire-page .panel-default>.panel-heading small,
.section-heading small {
    display: inline-block;
    padding: 0 5px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.campfire-page .chevron-icon {
    margin-right: 10px;
    color: #878684;
    font-size: 12px;
}

.campfire-page .panel-body {
    background-color: unset;
    border-top: unset !important;
    padding: 5px 45px;
}

.campfire-page .action-buttons {
    display: flex;
    /* justify-content: space-between; */
    grid-gap: 15px;
    gap: 15px;
    margin-top: 15px;
    padding: 0 15px;
}

.campfire-page .action-buttons button {
    border: unset;
    border-radius: 50px;
    padding: 5px 15px;

    font-family: Roboto;
    font-weight: 800;
    font-size: 16px;
    line-height: 18.75px;
    letter-spacing: -0.39px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    white-space: nowrap;
    grid-gap: 5px;
    gap: 5px;
}

.campfire-page .action-buttons button img {
    width: 14px;
}

.campfire-page .action-buttons button.primary.yellow {
    background-color: #F3D168;
}

.campfire-page .action-buttons button.primary.blue {
    background-color: #81B9EF;
}

.campfire-page .action-buttons button.primary.green {
    background-color: #82C2C0;
}

.campfire-page .panel-body table {
    border-bottom: 1px solid #d1d1d1;
}

.campfire-page .panel-body table thead tr th:nth-child(3) {
    text-align: unset;
}

.campfire-page .panel-body table thead tr th {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    color: #878684;
    white-space: nowrap;
}

.campfire-page .panel-body table thead tr th.name {
    max-width: 20px;
}

.campfire-page .panel-body table tbody tr td:nth-child(3) {
    text-align: unset;
}

.campfire-page .panel-body table tbody tr td:not(.actions) img {
    width: 12px;
    /* opacity: 0.5; */
    cursor: pointer;
}

.campfire-page .panel-body table tbody tr td:nth-child(4) a,
.campfire-page .panel-body table tbody tr td:nth-child(4) a:hover {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-style: solid;
            text-decoration-style: solid;
    text-underline-position: from-font;
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
    color: #000;
    /* margin-right: 15px; */
}

.campfire-page .panel-body table tbody tr td {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.campfire-page .panel-body table tbody tr td:nth-child(1) {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.campfire-page .campfire-footer {
    margin-bottom: 50px;
}

/* .campfire-page .campfire-header h2, */
.campfire-page .campfire-footer h2 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    line-height: 21.09px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.campfire-page .campfire-footer h2 {
    margin: 15px 0 !important;
    padding-left: 15px;
}

.campfire-page .campfire-footer p {
    padding-left: 15px;
}

.campfire-page .panel-default>.panel-heading .panel-title h4 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    line-height: 21.09px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

.table {
    width: 100%;
}

.table-responsive::-webkit-scrollbar,
.table-responsive::-moz-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    scrollbar-width: thin;
}

.campfire-page .custom-badge {
    margin-bottom: 0;
    display: inline-flex;
    grid-gap: 3px;
    gap: 3px;
    margin-right: 5px;
    padding: 5px 10px;
    color: #000;
}

.campfire-page .section-heading {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    align-items: anchor-center;
    font-family: Roboto;
    font-weight: 800;
    font-size: 18px;
    line-height: 21.09px;
    letter-spacing: 0px;
    padding: 0 15px;
    margin: 20px 0;
}

.campfire-page .section-heading .icon {
    width: 18px;
    opacity: 0.5;
    cursor: pointer;
}

.campfire-page .td-fte .custom-badge {
    font-family: Roboto;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.31px;
}

.campfire-page td.td-date,
.campfire-page td.td-name,
.campfire-page td.td-list,
.campfire-page td.td-member,
.campfire-page td.td-pills,
.campfire-page td.td-feedback,
.campfire-page td.td-notes,
.campfire-page .td-fte {
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow: hidden;
}

.campfire-page #feedback td.td-date {
    white-space: nowrap;
}

.campfire-page #feedback td.td-date,
.campfire-page #feedback td.td-name {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.campfire-page td.td-name {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
}

.campfire-page td.td-list {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    white-space: nowrap;
}

.campfire-page td.td-notes {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
}

.campfire-page td.td-feedback {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
}

.campfire-page .td-fte {
    width: 200px;
    min-width: 300px;
    max-width: 200px;
}

.campfire-page td.td-member {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
}

.campfire-page td.td-pills {
    
}

.campfire-page td.actions {
    width: 80px;
}

.campfire-page td.actions div {
    width: 100%;
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    justify-content: flex-end;
}

/* .campfire-page .table>tbody>tr>td, 
.campfire-page .table>tbody>tr>th, 
.campfire-page .table>tfoot>tr>td, 
.campfire-page .table>tfoot>tr>th, 
.campfire-page .table>thead>tr>td, 
.campfire-page .table>thead>tr>th {
    border-top: unset !important;
} */

.campfire-page .badges-container {
    display: inline-flex;
}

.campfire-page .list-green {
    background-color: #7fc2c0;
}

.campfire-page .collapsed .icon-wrapper {
    background-color: #eeeeee;
}

.campfire-page .expanded .icon-wrapper {
    /* background-color: #dddddd; */
    border: 1px solid #dddddd;
}

.campfire-page .tracking-part h2 {
    display: flex;
    justify-content: start;
    align-items: center;
}

.campfire-page .icon-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    /* padding: 5px; */
    margin-left: 30px;
    border-radius: 5px;
}

.campfire-page .icon-wrapper img {
    width: 28px;
    height: 28px;
}
