/* Home page CSS Only */
.home-page .board-body {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.home-page.container-fluid {
    max-width: 1300px;
    /* padding-top: 97px; */
    padding-bottom: 60px;
    padding-left: 0px;
    margin-left: 15px;
}

/* .home-page h2 {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 800;
    line-height: 37.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
} */

.home-page p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.home-page .home-body {
    margin-top: 50px;
}

.home-page .panel-default {
    margin-bottom: 15px;
}

.home-page .panel-default>.panel-heading {
    background-color: unset;
    border: none;
    font-family: Roboto;
    font-size: 18px !important;
    font-weight: 800 !important;
    line-height: 21.09px !important;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.home-page .panel-default>.panel-heading a:hover,
.home-page .panel-default>.panel-heading a:focus {
    text-decoration: none;
}

.home-page .panel-default>.panel-heading small {
    display: block;
    padding: 5px 29px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.home-page .chevron-icon {
    margin-right: 10px;
    color: #878684;
    font-size: 12px;
}

.home-page .panel-body {
    background-color: unset;
    border-top: unset !important;
    padding: 5px 45px;
}

.home-page .panel-body .action-buttons {
    display: flex;
}

.home-page .panel-body button {
    width: 187px;
    height: 44px;
    top: 725px;
    left: 170px;
    gap: 0px;
    border-radius: 50px;
    /* opacity: 0px; */
    background-color: #F2D172;
    border: unset;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;

    margin-right: 45px;
}

.home-page .panel-body button.secondary {
    background-color: #E7E7E7;
}

.home-page .panel-body table {
    border-bottom: 1px solid #d1d1d1;
    width: 100%;
}

.home-page .panel-body table thead tr th:nth-child(3) {
    text-align: center;
}

.home-page .panel-body table thead tr th {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #878684;
}

.home-page .panel-body table tbody tr td:nth-child(3) {
    text-align: center;
}

.home-page .panel-body table tbody tr td:nth-child(3) img {
    width: 20px;
    opacity: 0.5;
    cursor: pointer;
}

.home-page .panel-body table tbody tr td:nth-child(4) a,
.home-page .panel-body table tbody tr td:nth-child(4) a:hover {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: auto;
    color: #000;
    margin-right: 15px;
}

.home-page .panel-body table tbody tr td {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.home-page .panel-body table tbody tr td:nth-child(1) {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    letter-spacing: -0.30777785181999207px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.home-page .panel-body table tbody tr td.td-feature {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
}

.home-page .panel-body table tbody tr td.td-description {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
}

.home-page .panel-body table tbody tr td.td-info {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}

/* .home-page .home-header h2, */
.home-page .home-footer h2 {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 800;
    line-height: 21.09px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.home-page .home-footer h2 {
    margin-bottom: 0;
}

.home-page .home-footer p {
    padding: 5px 44px;
}

.home-page .panel-default>.panel-heading .panel-title h4 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 800;
    line-height: 21.09px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.table-responsive::-webkit-scrollbar,
.table-responsive::-moz-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    scrollbar-width: thin;
}

/* .home-page .tracking-part h2 {
    margin-top: 10px;
} */

.home-page .collapsed .icon-wrapper {
    background-color: #eeeeee;
}

.home-page .expanded .icon-wrapper {
    /* background-color: #dddddd; */
    border: 1px solid #dddddd;
}

.home-page .tracking-part h2 {
    display: flex;
    justify-content: start;
    align-items: center;
}

.home-page .icon-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    /* padding: 5px; */
    margin-left: 30px;
    border-radius: 5px;
}

.home-page .icon-wrapper img {
    width: 28px;
    height: 28px;
}

/* @media (max-width: 991px) {
    .home-page.container-fluid {
        padding-top: 67px;
    }

    .app-header {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }

    .left {
        grid-row: 1;
    }

    .center {
        grid-row: 2;
        width: 50%;
    }

    .right {
        grid-row: 2;
        width: 50%;
        grid-gap: 0 !important;
    }
}

@media (max-width: 768px) {
    .home-page.container-fluid {
        padding-top: 47px;
    }

    .home-page .panel-body button.secondary {
        margin-right: 0;
    }
}

@media (max-width: 580px) {
    .home-page .panel-body button.primary {
        margin-right: 10px;
    }
}

@media (max-width: 480px) {
    .home-page.container-fluid {
        padding-top: 27px;
    }

    .home-page .panel-body button {
        font-size: 14px;
    }
} */